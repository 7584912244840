import React, { useState, Fragment, useRef, FormEvent } from 'react';
import { PlusIcon, EllipsisHorizontalIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog, Menu, Transition } from '@headlessui/react';
import Tags from '@yaireo/tagify/dist/react.tagify';
import { toast } from 'react-toastify';
import { Table } from 'flowbite-react';
import { AxiosResponse } from 'axios';

import { TemplateModel } from '../../../models/template.model';
import { templateService } from '../../../services/TemplateService';
import { ErrorsSummery } from '../atoms/ErrorsSummery';
import { getsStatistics$ } from '../../../services/UserService';
import Loader from '../icons/loader';
import { useCreateTemplate, useGetTemplates, useUpdateTemplate } from '../../../hooks';
import { useTour } from '@reactour/tour';

class TemplateForm extends TemplateModel {
  public template?: FileList;
}

interface HTMLFormElementExtended extends HTMLFormElement {
  getValues<T>(): T;
  getValues(): any;
}

HTMLFormElement.prototype.getValues = function () {
  const obj: any = {};

  for (let i = 0; i < this.length; i++) {
    const formElement = this[i] as HTMLInputElement;

    if (formElement.name)
      if (formElement.type === 'file') obj[formElement.name] = formElement.files;
      else obj[formElement.name] = formElement.value;
  }

  return obj;
};

class TemplatesState {
  public isTemplateEditing?: boolean;
  public editingTemplate?: TemplateModel;
  public newTemplateFile?: File;
  public deletingTemplate?: TemplateModel;
}

class ModalsState {
  public showTemplateModal?: boolean;
  public showConfirmModal?: boolean;
  public showTemplateValidationModal?: boolean;
}

function Templates() {
  const tour = useTour();
  const [state, setState] = useState<TemplatesState>({
    isTemplateEditing: false,
    editingTemplate: undefined,
  });

  const {
    data: templates,
    isLoading: templatesLoading,
    refetch: refetchTemplates,
  } = useGetTemplates('');

  const createTemplate = useCreateTemplate();
  const updateTemplate = useUpdateTemplate();

  const [file, setFile] = useState<File>();

  const [disabled, setDisabled] = useState<boolean>(false);

  const [modals, setModals] = useState<ModalsState>({
    showTemplateModal: false,
    showConfirmModal: false,
    showTemplateValidationModal: false,
  });

  const [errors, setErrors] = useState<string[]>([]);
  const [templateValidationError, setTemplateValidationError] = useState<string>();
  const [templateValidationSuccess, setTemplateValidationSuccess] = useState<boolean>(false);

  const cancelTemplateButtonRef = useRef(null);
  const cancelConfirmButtonRef = useRef(null);
  const cancelTemplateValidationButtonRef = useRef(null);

  // When dropzone implemented
  // const onDrop = useCallback((acceptedFiles: File[]) => {
  //   if (acceptedFiles.length) {
  //     setState({
  //       ...state,
  //       newTemplateFile: acceptedFiles[0]
  //     })
  //   }
  // }, []);

  // When using dropzone
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   onDrop,
  //   accept: {
  //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
  //   }
  // });

  const selectTemplateToEdit = (template: TemplateModel) => {
    setState({
      ...state,
      isTemplateEditing: true,
      editingTemplate: template,
    });

    setModals({
      ...modals,
      showTemplateModal: true,
    });
  };

  const closeTemplateModal = () => {
    setState({
      ...state,
      isTemplateEditing: false,
      editingTemplate: undefined,
    });

    setModals({
      ...modals,
      showTemplateModal: false,
    });
  };

  const formSubmitHandler = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    var form = ev.target as HTMLFormElementExtended;
    const template = form.getValues<TemplateForm>();
    template.tags =
      template.tags && template.tags.length
        ? (JSON.parse(template.tags!) as [{ value: string }]).map((x) => x.value).join(',')
        : undefined;

    setErrors(validateForm(template));
    if (!errors.length) {
      await saveTemplate(template, template.template!);
      nextStep();
    }
  };

  const validateForm = (template: TemplateForm) => {
    const localErrors = [];
    if (!template.name || !template.name.length || template.name.length < 5)
      localErrors.push('Name cannot be empty or less then 5 characters');

    if (!state.isTemplateEditing)
      if (!template.template?.length) localErrors.push('Template file is required');
      else if (template.template?.length > 5 * 1024)
        localErrors.push('File should not be greater then ');

    return localErrors;
  };

  const deleteTemplate = (id: string) => {
    setDisabled(true);
    templateService
      .delete(id)
      .then(() => {
        setModals({ ...modals, showConfirmModal: false });
        refetchTemplates();
      })
      .finally(() => {
        setDisabled(false);
        getsStatistics$.next(undefined);
      });
  };

  const showConfirmationModal = (model?: TemplateModel) => {
    setModals({ ...modals, showConfirmModal: true });
    setState({ ...state, deletingTemplate: model });
  };

  const closeConfirmationModal = () => {
    setModals({ ...modals, showConfirmModal: false });
    setState({ ...state, deletingTemplate: undefined });
  };

  const saveTemplate = async (templateModel: TemplateModel, files: FileList) => {
    let promise: Promise<AxiosResponse<TemplateModel>>;
    if (state.isTemplateEditing) {
      promise = updateTemplate.mutateAsync({
        id: state.editingTemplate?.id,
        template: templateModel,
        file: files[0],
      });
    } else {
      if (!files.length) toast.error('File is required');

      promise = createTemplate.mutateAsync({ template: templateModel, file: files[0] });
    }

    setDisabled(true);
    try {
      await promise;
    } finally {
      setDisabled(false);
    }
    setState({
      ...state,
      isTemplateEditing: false,
      editingTemplate: undefined,
      newTemplateFile: undefined,
    });

    setModals({
      ...modals,
      showConfirmModal: false,
      showTemplateModal: false,
    });

    getsStatistics$.next(undefined);

    refetchTemplates();
  };

  const openTemplateValidationModal = () => {
    var currentForm = document.forms[0] as HTMLFormElementExtended;
    if (currentForm && currentForm?.template?.files?.length) {
      setModals({ ...modals, showTemplateValidationModal: true });
      setFile(currentForm?.template?.files[0]);
    }
  };

  const closeTemplateValidationModal = () => {
    setModals({ ...modals, showTemplateValidationModal: false });
    setTemplateValidationSuccess(false);
    setFile(undefined);
  };

  const validateTemplate = (e: any) => {
    e.preventDefault();
    var form = e.target as HTMLFormElementExtended;
    const validationObj = form.getValues<{ obj: string }>();
    if (file) {
      setDisabled(true);
      templateService
        .validate(file, JSON.stringify(JSON.parse(validationObj.obj)))
        .then(() => {
          setTemplateValidationError(undefined);
          setTemplateValidationSuccess(true);
        })
        .catch((err) => {
          console.dir(err);
          setTemplateValidationSuccess(false);
          if (err) setTemplateValidationError(err.message);
        })
        .finally(() => setDisabled(false));
    }
  };

  const downloadHandler = (model: TemplateModel) => {
    templateService.download(model.id!).then((blob: Blob) => {
      var csvURL = window.URL.createObjectURL(blob);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', `${model.name}.docx`);
      tempLink.click();
    });
  };

  const nextStep = () => {
    setTimeout(() => {
      tour.setCurrentStep(tour.currentStep + 1);
    }, 200);
  };

  return (
    <div className="step-8">
      <div className="w-full text-sm mb-5">
        <div className="flex">
          <div className="w-full pr-5">
            <input
              type="search"
              name="Search"
              placeholder="Search..."
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:text-white"
            />
          </div>
          <div className="">
            <button
              type="button"
              onClick={() => {
                setModals({ ...modals, showTemplateModal: true });
                nextStep();
              }}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 step-10"
            >
              <PlusIcon className="h-6 w-6"></PlusIcon>
            </button>
          </div>
        </div>
      </div>

      {templatesLoading ? (
        <div className="flex flex-wrap gap-2 justify-center place-content-center h-60 bg-gray-300/50">
          <div className="text-center size-10">
            <Loader className="w-20 h-20" />
          </div>
        </div>
      ) : (
        <Table className="table-auto">
          <Table.Head>
            <Table.HeadCell>Template name</Table.HeadCell>
            <Table.HeadCell>Tags</Table.HeadCell>
            <Table.HeadCell>Id</Table.HeadCell>
            <Table.HeadCell></Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {templates.data?.length ? (
              templates.data.map((x: any, index: number) => (
                <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell>{x.name}</Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {x.tags?.split(',').map((t: any, index: number) => (
                      <span
                        key={index}
                        className="mr-2 inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
                      >
                        {t.trim()}
                      </span>
                    ))}
                  </Table.Cell>
                  <Table.Cell className="step-9">{x.id}</Table.Cell>
                  <Table.Cell className="px-6 py-4 text-right">
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="mr-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                          <EllipsisHorizontalIcon className="h-6 w-6"></EllipsisHorizontalIcon>
                        </Menu.Button>
                      </div>
                      <Menu.Items className="cursor-pointer text-left absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {() => (
                            <span
                              onClick={() => selectTemplateToEdit(x)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-sky-700 hover:text-[#ffffff]"
                            >
                              Edit
                            </span>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {() => (
                            <span
                              onClick={() => showConfirmationModal(x)}
                              className="block px-4 py-2 text-sm text-red-900 font-bold hover:bg-sky-500"
                            >
                              Delete
                            </span>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {() => (
                            <span
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-sky-700 hover:text-[#ffffff]"
                              onClick={() => downloadHandler(x)}
                            >
                              Download
                            </span>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Menu>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell colSpan={4} className="px-6 py-4 text-center text-xl">
                  No templates found
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      )}

      {/* Add/edit form modal */}
      <Transition.Root show={modals.showTemplateModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelTemplateButtonRef}
          onClose={() => closeTemplateModal()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="bg-[#4ECDC4] relative transform overflow-hidden rounded-lg 
                  bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-8 step-11"
                >
                  <Dialog.Title>
                    <div className="flex items-start justify-between pb-4 border-b rounded-t dark:border-gray-600">
                      <h1 className="text-2xl">
                        Template {state.isTemplateEditing ? 'editing' : 'creation'}
                      </h1>
                      <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        ref={cancelTemplateButtonRef}
                        onClick={() => setModals({ ...modals, showTemplateModal: false })}
                      >
                        <XMarkIcon className="h-6 w-6"></XMarkIcon>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                  </Dialog.Title>

                  <br />
                  <form
                    name="template-form"
                    className="w-full max-w-lg"
                    onSubmit={(ev) => formSubmitHandler(ev)}
                  >
                    {state.isTemplateEditing ? (
                      <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            Template Id
                          </label>
                          <input
                            name="id"
                            className="appearance-none block w-full 
                            border border-gray-200 rounded py-3 px-4
                             mb-3 leading-tight focus:outline-none focus:bg-white 
                             focus:border-gray-500 disabled:bg-slate-50 
                             disabled:text-slate-500 disabled:border-slate-200 
                             disabled:shadow-none"
                            type="text"
                            disabled
                            defaultValue={state.editingTemplate?.id}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          Name <small>should have at least 5 leters</small>
                        </label>
                        <input
                          className="appearance-none block w-full border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          name="name"
                          type="text"
                          minLength={5}
                          defaultValue={state.editingTemplate?.name}
                          placeholder="Type in template name, aka 'Customer order'"
                        />
                      </div>
                    </div>

                    {/* Dropzone */}
                    {/* <div className="flex items-center justify-center w-full " {...getRootProps()}>
                      <input
                        name='template'
                        {...getInputProps()} />
                      <label
                        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            className="w-10 h-10 mb-3 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            >
                            </path>
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                        </div>
                      </label>
                    </div> */}

                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Tags
                        </label>

                        <Tags
                          className="w-full"
                          showDropdown={false}
                          defaultValue={state.editingTemplate?.tags}
                          name="tags"
                          placeholder="Tags"
                        />
                      </div>
                    </div>

                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Upload .docx template
                    </label>

                    <div className="relative w-full">
                      <input
                        name="template"
                        className="
                          border-gray-200 shadow-sm rounded-md 
                          focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400
                          file:bg-transparent file:border-0
                          file:bg-gray-100 file:mr-4
                          file:py-3 file:px-4
                          dark:file:bg-gray-700 dark:file:text-gray-400
                            
                          block w-full z-20 text-sm text-gray-900 bg-gray-50  
                          border-m-gray-100 border-m-2 border border-gray-300 focus:ring-blue-500
                          focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 
                          dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                        type="file"
                        accept="application/msword,
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      />
                      <button
                        type="button"
                        className="
                          absolute top-0 right-0 p-2.5 h-full text-sm font-medium text-white 
                          bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 
                          focus:ring-4 focus:outline-none focus:ring-blue-300 
                          dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => openTemplateValidationModal()}
                        ref={cancelTemplateButtonRef}
                      >
                        Validate
                      </button>
                    </div>

                    {errors.length ? <ErrorsSummery errors={errors} /> : ''}

                    <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="submit"
                        disabled={disabled}
                        className="inline-flex w-full justify-center rounded-md 
                          bg-green-600 px-3 py-2 text-sm font-semibold 
                          text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => closeTemplateModal()}
                        ref={cancelTemplateButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Confirmation modal */}
      <Transition.Root
        show={modals.showConfirmModal && state.deletingTemplate !== undefined}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelConfirmButtonRef}
          onClose={() => closeConfirmationModal()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="bg-[#4ECDC4] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-8">
                  <button
                    type="button"
                    className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    ref={cancelConfirmButtonRef}
                    onClick={() => setModals({ ...modals, showConfirmModal: false })}
                  >
                    <XMarkIcon className="h-6 w-6"></XMarkIcon>
                    <span className="sr-only">Close modal</span>
                  </button>

                  <p className="mb-4 text-gray-500 dark:text-gray-300">
                    Are you sure you want to delete{' '}
                    <span className="text-red-500 text-bold">
                      &quot;{state.deletingTemplate?.name}&quot;
                    </span>{' '}
                    template?
                  </p>
                  <div className="flex justify-center items-center space-x-4">
                    <button
                      type="button"
                      className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                      onClick={() => closeConfirmationModal()}
                    >
                      No, cancel
                    </button>
                    <button
                      type="submit"
                      className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
                      onClick={() => deleteTemplate(state.deletingTemplate?.id!)}
                      disabled={disabled}
                    >
                      Yes, I&apos;m sure
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Template validation modal */}
      <Transition.Root show={modals.showTemplateValidationModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelTemplateValidationButtonRef}
          onClose={() => closeConfirmationModal()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="bg-[#4ECDC4] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-8">
                  <Dialog.Title>
                    <div className="flex items-start justify-between pb-4 border-b rounded-t dark:border-gray-600">
                      <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Template validation
                      </h1>
                      <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        ref={cancelConfirmButtonRef}
                        onClick={() => setModals({ ...modals, showTemplateValidationModal: false })}
                      >
                        <XMarkIcon className="h-6 w-6"></XMarkIcon>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                  </Dialog.Title>

                  <div className="pt-4 space-y-6">
                    <label>Pass object for current template</label>

                    <form onSubmit={(e) => validateTemplate(e)}>
                      <textarea
                        name="obj"
                        rows={10}
                        className="block p-2.5 w-full text-sm text-gray-900 b
                          g-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500
                          dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 
                          dark:focus:border-blue-500"
                        placeholder="{}"
                      ></textarea>

                      {templateValidationSuccess ? (
                        <div
                          className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
                          role="alert"
                        >
                          <span className="font-medium">Success</span>
                        </div>
                      ) : (
                        <></>
                      )}

                      {templateValidationError ? (
                        <div
                          className="p-4 my-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                          role="alert"
                        >
                          {templateValidationError}
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="py-3 sm:flex sm:flex-row-reverse">
                        <button
                          type="submit"
                          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                          disabled={disabled}
                        >
                          Validate
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => closeTemplateValidationModal()}
                          ref={cancelTemplateValidationButtonRef}
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default Templates;
