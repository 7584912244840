import { TemplateModel } from '../models/template.model';
import { BaseSevice } from './BaseService';

class TemplateService extends BaseSevice {
  apiUrl = 'api/template';

  getFiltered(q?: any) {
    return this.request<any>(
      `${this.apiUrl}`,
      'get',
      {
        params: { q },
      },
      null,
    ).then((res) => res.data);
  }

  download(id: string): Promise<Blob> {
    return this.request<Blob>(`${this.apiUrl}/download/${id}`, 'get', {
      responseType: 'blob',
    }).then((res) => res.data);
  }

  createTemplate(template: TemplateModel, templateFile?: File) {
    const form = new FormData();
    if (!template.name) {
      throw new Error('Name should not be empty');
    }

    form.append('name', template.name || '');
    form.append('tags', template.tags || '');

    if (templateFile && templateFile.size) {
      form.append('template', templateFile);
    }

    return this.request<TemplateModel>(`${this.apiUrl}`, 'post', undefined, form);
  }

  validate(templateFile: File, dataObj: string) {
    const form = new FormData();
    form.append('file', templateFile);
    form.append('data', dataObj);

    return this.request<boolean>(`${this.apiUrl}/process/validate`, 'post', undefined, form);
  }

  updateTemplate(id: string, template: TemplateModel, templateFile?: File) {
    const form = new FormData();
    if (!template.name) {
      throw new Error('Name should not be empty');
    }

    form.append('name', template.name || '');
    form.append('tags', template.tags || '');

    if (templateFile && templateFile.size) {
      form.append('template', templateFile);
    }

    return this.request<TemplateModel>(`${this.apiUrl}/${id}`, 'put', undefined, form);
  }

  delete(id: string) {
    return this.request(`${this.apiUrl}/${id}`, 'delete');
  }
}

export const templateService = new TemplateService();
