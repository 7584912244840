import React, { useEffect } from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';

import router from './routes';
import { userService } from './services/UserService';

function App() {
  useEffect(() => {
    userService.loadUser();
  }, []);

  return (
    <div className="bg-gradient-to-r from-black-start to-black-end min-h-screen">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
