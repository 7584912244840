// import React, { Fragment, useEffect, useRef, useState } from 'react';
// import { Button, Card, RangeSlider } from 'flowbite-react';
// import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useRef, useState } from 'react';
import { Button, RangeSlider } from 'flowbite-react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Dialog, Transition } from '@headlessui/react';
// import clx from 'classnames';

// import planService from '../../../../services/PlanService';
import { PlanModel } from '../../../../models/plan.model';
import { PaymentButton } from '../../atoms/PaymentButton';
// import { PlanEnum } from '../../../../enums/plan.enum';
// import { plan$ } from '../../../../services/UserService';
import { CreatePaymentModel } from '../../../../models/create-payment.model';
import { PaymentProductEnum } from '../../../../enums/payment-product.enum';

class PaymentDetails {
  public selectedPlan?: PlanModel;
  public isModalOpen?: boolean;
  public product?: CreatePaymentModel;
}

export default function Plans() {
  // const [plans, setPlans] = useState<PlanModel[]>();
  // const [currentPlan, setCurrentPlan] = useState<PlanModel>();
  const [additionalRequestCount, setAdditionalRequestCount] = useState(1000);
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>({ isModalOpen: false });

  const checkoutModalRef = useRef(null);
  const checkoutModalButtonRef = useRef(null);

  // useEffect(() => {
  //   planService.get().then((plans) => {
  //     setPlans(plans);
  //   });
  //   plan$.subscribe((plan) => setCurrentPlan(plan));
  // }, []);

  // const selectPlan = (plan: PlanModel) => {
  //   setPaymentDetails({
  //     selectedPlan: plan,
  //     product: {
  //       product: PaymentProductEnum.Plan,
  //       value: plan.id,
  //     },
  //     isModalOpen: true,
  //   });
  // };

  const purchaseMoreRequests = () => {
    setPaymentDetails({
      isModalOpen: true,
      product: {
        product: PaymentProductEnum.Requests,
        value: additionalRequestCount,
      },
    });
  };

  const closeCheckoutModal = () => {
    setPaymentDetails({ isModalOpen: false });
  };

  return (
    <>
      <h2 className="text-white text-2xl text-bold mb-5 font-bold">Shop</h2>

      <div className="mb-5">
        <div className="mb-1 block">
          <label
            className="font-medium text-gray-900 dark:text-gray-300 text-white text-lg"
            htmlFor="request-slider"
          >
            Purchase more {additionalRequestCount} requests
          </label>
        </div>

        <RangeSlider
          id="request-slider"
          onChange={(e) => setAdditionalRequestCount(Number(e.target.value))}
          value={additionalRequestCount}
          min={1000}
          step={500}
          max={20_000}
        />

        <div className="mt-5">
          <Button onClick={() => purchaseMoreRequests()}>Purchase</Button>
        </div>
      </div>

      {/* <div className="flex justify-items-center gap-4 xs:flex-wrap">
        {plans?.map((p, index) => (
          <Card
            key={index}
            className={clx('max-w-sm basis-1/3 shadow-lg shadow-white', {
              'border-8 border-green-600': p.planEnum == currentPlan?.planEnum,
            })}
          >
            <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400 text-center">
              {p.name}
            </h5>
            <div className="flex items-baseline text-gray-900 dark:text-white">
              <span className="text-3xl font-semibold">$</span>
              <span className="text-5xl font-extrabold tracking-tight">{p.price}</span>
              <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">
                /month
              </span>
            </div>
            <ul className="my-7 space-y-5">
              <li className="flex space-x-3">
                <CheckCircleIcon className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500" />
                <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
                  {p.maxRequestCount} requests
                </span>
              </li>
              <li className="flex space-x-3">
                <CheckCircleIcon className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500" />
                <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
                  {p.maxTemplateCount} templates
                </span>
              </li>
              <li className="flex space-x-3">
                <CheckCircleIcon className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500" />
                <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
                  {p.maxApplicationCount} applications
                </span>
              </li>
              <li className="flex space-x-3">
                <CheckCircleIcon className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500" />
                <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
                  {p.maxConcurrentProcessingsCount} concurrent processors
                </span>
              </li>
              <li className="flex space-x-3">
                <CheckCircleIcon className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500" />
                <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
                  Email support
                </span>
              </li>
              <li className="flex space-x-3">
                <CheckCircleIcon className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500" />
                <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
                  Complete documentation
                </span>
              </li>
            </ul>
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-lg bg-cyan-600 px-5 py-2.5 text-center 
                text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-4 
                focus:ring-cyan-200 dark:focus:ring-cyan-900 disabled:bg-slate-500"
              disabled={p.planEnum == PlanEnum.Free || p.planEnum == currentPlan?.planEnum}
              onClick={() => selectPlan(p)}
            >
              Choose plan
            </button>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://pritset.com/docs/plans"
              className="text-sm font-medium text-cyan-600 hover:underline dark:text-cyan-500"
            >
              More details
            </a>
          </Card>
        ))}
      </div> */}

      <Transition.Root show={paymentDetails?.isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={checkoutModalRef}
          onClose={() => closeCheckoutModal()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="bg-[#4ECDC4] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-8">
                  <button
                    type="button"
                    className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    ref={checkoutModalButtonRef}
                    onClick={() => closeCheckoutModal()}
                  >
                    <XMarkIcon className="w-5 h-5" />
                    <span className="sr-only">Close modal</span>
                  </button>
                  <h2 className="text-bold text-center mb-3.5 mx-auto">
                    {paymentDetails?.product?.product == PaymentProductEnum.Plan
                      ? paymentDetails?.selectedPlan?.name
                      : 'Additional requests'}
                  </h2>

                  <p className="text-bold mb-4 text-gray-500 dark:text-gray-300">
                    You are on to purchase
                    {paymentDetails?.product?.product == PaymentProductEnum.Plan
                      ? ' a plan'
                      : ` additional ${paymentDetails?.product?.value} requests`}
                  </p>

                  {paymentDetails?.product?.product == PaymentProductEnum.Plan ? (
                    <pre className="mb-4 text-gray-500 dark:text-gray-300">
                      {paymentDetails?.selectedPlan?.description}
                    </pre>
                  ) : (
                    <></>
                  )}

                  <div className="flex justify-center items-center space-x-4">
                    <PaymentButton product={paymentDetails?.product} />

                    <button
                      type="button"
                      className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                      onClick={() => closeCheckoutModal()}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
