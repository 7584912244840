import React, { useEffect, useState } from 'react';
import { Button, Table } from 'flowbite-react';
import {
  ArrowDownTrayIcon,
  CheckCircleIcon,
  XCircleIcon,
  ArrowPathRoundedSquareIcon,
} from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

import paymentService from '../../../../services/PaymentService';
import { PaymentModel } from '../../../../models/payment-model';
import { PaymentStatusEnum } from '../../../../enums/payment-status.enum';

export default function Payments() {
  const [payments, setPayments] = useState<PaymentModel[]>();

  useEffect(() => {
    paymentService.getFiltered().then((res) => {
      setPayments(res.data);
    });
  }, []);

  const getStatusIcon = (status: PaymentStatusEnum) => {
    switch (status) {
      case PaymentStatusEnum.Success:
        return <CheckCircleIcon className="w-10 h-10 text-green-600" />;
      case PaymentStatusEnum.Failed:
        return <XCircleIcon className="w-10 h-10 text-red-600" />;
      case PaymentStatusEnum.Created:
        return <ArrowPathRoundedSquareIcon className="w-10 h-10 text-bold text-blue-600" />;
    }
  };

  return (
    <>
      <h2 className="text-white text-2xl text-bold mb-5 font-bold">Payments</h2>

      <Table className="overflow-x-auto">
        <Table.Head>
          <Table.HeadCell>Status</Table.HeadCell>
          <Table.HeadCell>Created At</Table.HeadCell>
          <Table.HeadCell>Service</Table.HeadCell>
          <Table.HeadCell>Total pay</Table.HeadCell>
          <Table.HeadCell className="w-12">Download check</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {payments?.length ? (
            payments.map((x, index) => (
              <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell>{getStatusIcon(x.status!)}</Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {dayjs(x.dateCreated).format('DD/MM/YYYY HH:mm')}
                </Table.Cell>
                <Table.Cell>{x.subscriptionName}</Table.Cell>
                <Table.Cell>${x.amount}</Table.Cell>
                <Table.Cell>
                  <Button>
                    <ArrowDownTrayIcon className="h-6 w-6 text-white mr-2" /> Download
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <></>
          )}
        </Table.Body>
      </Table>
    </>
  );
}
