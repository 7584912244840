import { ApplicationModel } from '../models/application.model';
import { BaseSevice } from './BaseService';

class ApplicationService extends BaseSevice {
  apiUrl = 'api/application';

  getFiltered(q?: any) {
    return this.request(
      `${this.apiUrl}`,
      'get',
      {
        params: { q },
      },
      null,
    ).then((res: any) => {
      return res.data;
    });
  }

  create(application: ApplicationModel) {
    if (!application.name) {
      throw new Error('Name should not be empty');
    }

    return this.request<ApplicationModel>(`${this.apiUrl}`, 'post', undefined, application);
  }

  update(id: string, application: ApplicationModel) {
    if (!application.name) {
      throw new Error('Name should not be empty');
    }

    return this.request<ApplicationModel>(`${this.apiUrl}/${id}`, 'put', undefined, application);
  }

  regenerateKey(id: string) {
    return this.request<string>(`${this.apiUrl}/regenerate-key/${id}`, 'put');
  }

  delete(id: string) {
    return this.request(`${this.apiUrl}/${id}`, 'delete');
  }
}

const applicationService = new ApplicationService();

export default applicationService;
