import React from 'react';
import { useLocation } from 'react-router-dom';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import classNames from 'classnames';

import { RouteDefinition, routeDefinitions } from '../../../routes';
import { useTour } from '@reactour/tour';

export function Sidebar() {
  const currentLocation = useLocation();
  const tour = useTour();

  const getActiveClasses = (routeDefinition: RouteDefinition) => {
    return classNames({
      'rounded-md': true,
      'pl-3 bg-green-600': currentLocation.pathname.includes(routeDefinition.route!),
    });
  };

  const nextTourStep = () => {
    if (tour.isOpen)
      setTimeout(() => {
        tour.setCurrentStep(tour.currentStep + 1);
      }, 300);
  };

  return (
    <aside
      id="logo-sidebar"
      className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full border-r
        bg-gradient-to-t from-robin-egg-blue-75 via-rich-black-10 to-robin-egg-blue-15
        border-robin-egg-blue-15
        backdrop-blur-20
        sm:translate-x-0 sm:bg-black"
      aria-label="Sidebar"
    >
      <div className="h-full px-3 pb-4 overflow-y-auto dark:bg-gray-800">
        <ul className="font-medium flex flex-col place-content-between h-full">
          <div>
            {Object.keys(routeDefinitions)
              .filter((rdk) => routeDefinitions[rdk].isSidebar)
              .slice(1)
              .map((rd, index) => (
                <li
                  key={index}
                  onClick={
                    routeDefinitions[rd].route === 'applications' ||
                    routeDefinitions[rd].route === 'templates' ||
                    routeDefinitions[rd].route === 'labs'
                      ? () => nextTourStep()
                      : undefined
                  }
                  className={getActiveClasses(routeDefinitions[rd])}
                >
                  <Link
                    to={`/${routeDefinitions[rd].route}`}
                    className={clsx(
                      'flex items-center p-2 space-x-3 rounded-md text-gray-100',
                      routeDefinitions[rd].class,
                    )}
                  >
                    {routeDefinitions[rd].icon}
                    <span className="text-gray-100">{routeDefinitions[rd].text}</span>
                  </Link>
                </li>
              ))}
          </div>
          <li className="rounded-sm">
            <a
              href="https://pritset.com/docs/intro"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center p-2 space-x-3 rounded-md text-gray-100"
            >
              <BookOpenIcon className="h-6 w-6"></BookOpenIcon>
              <span className="text-gray-100">Docs</span>
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
}
