import React from 'react';
import { Navbar } from '../molecules/Navbar';
import { Sidebar } from '../molecules/Sidebar';
import Content from '../../Content';

function Root() {
  return (
    <>
      <Navbar />
      <Sidebar />
      <Content />
    </>
  );
}

export default Root;
