import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';

class PieChartProps {
  public maxValue?: number;
  public value?: number;
}

const RADIAN = Math.PI / 180;

const needle = (value: number, total: number, cx: number, cy: number, iR: number, oR: number) => {
  // console.log('needle: ', value, total || 1);
  const ang = 180.0 * (1 - value / total);
  // console.log(value, total, ang);
  // console.log(ang);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  // console.log(`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`);

  return [
    <circle key={1} cx={x0} cy={y0} r={r} fill="#959595" stroke="black" />,
    <path
      key={2}
      d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
      stroke="black"
      fill="#959595"
    />,
  ];
};

function SPPieChart({ maxValue, value }: PieChartProps) {
  // value = value! > maxValue! ? value! - maxValue! : value;

  // console.log('Chart arguments: ', arguments);

  value = value || 1;

  const data = [{ name: 'A', value, color: '#ff0000' }];

  const cx = 100;
  const cy = 150;
  const iR = 80;
  const oR = 100;

  return (
    <PieChart width={210} height={200}>
      <defs>
        <linearGradient id="colorUv" x1="1" y1="0" x2="0" y2="0">
          <stop offset="0%" stopColor="#ff0000" stopOpacity={1} />
          <stop offset="40%" stopColor="#0400ff" stopOpacity={1} />
          <stop offset="100%" stopColor="#00ff14" stopOpacity={1} />
        </linearGradient>
      </defs>
      <Pie
        dataKey="value"
        startAngle={180}
        endAngle={0}
        data={data}
        cx={cx}
        cy={cy}
        innerRadius={iR}
        outerRadius={oR}
        fill="yellow"
        stroke="black"
        animationDuration={500}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill="url(#colorUv)" />
        ))}
      </Pie>
      {needle(value!, maxValue!, cx, cy, iR, oR)}
    </PieChart>
  );
}

export default SPPieChart;
