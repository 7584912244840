import { useMutation, useQueryClient } from '@tanstack/react-query';
import { templateService } from '../../services/TemplateService';
import { TemplateModel } from '../../models/template.model';

export const useCreateTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (override: { template: TemplateModel; file: File }) =>
      templateService.createTemplate(override.template, override.file),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates'] });
    },
  });
};

export const useUpdateTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (override: { id: string | undefined; template: TemplateModel; file: File }) =>
      templateService.updateTemplate(override.id!, override.template, override.file),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates'] });
    },
  });
};
