import React from 'react';
import { createBrowserRouter, Link, Navigate } from 'react-router-dom';
import { ChartBarIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';

import Root from './components/atomic-design/pages/Root';
import Dashboard from './components/atomic-design/pages/Dashboard';
import Applications from './components/atomic-design/pages/Applications';
import Templates from './components/atomic-design/pages/Templates';
import Labs from './components/atomic-design/pages/Labs';
import LabeTubesIcon from './components/atomic-design/icons/lab-tube';
import Account from './components/atomic-design/pages/settings/Account';

export class RouteDefinition {
  public route?: string;
  public text?: string;
  public icon?: JSX.Element;
  public class?: string = '';
  public isSidebar = false;
}

export const routeDefinitions: { [key: string]: RouteDefinition } = {
  '/': {
    route: '',
    text: 'Dashboard',
    icon: <ChartBarIcon className="h-6 w-6" />,
    isSidebar: true,
  },
  dashboard: {
    route: 'dashboard',
    text: 'Dashboard',
    icon: <ChartBarIcon className="h-6 w-6" />,
    isSidebar: true,
  },
  applications: {
    route: 'applications',
    text: 'Applications',
    class: 'step-2',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-6 h-6 text-gray-100"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={1.5}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
        />
      </svg>
    ),
    isSidebar: true,
  },
  templates: {
    route: 'templates',
    text: 'Templates',
    icon: <ClipboardDocumentCheckIcon className="h-6 w-6" />,
    isSidebar: true,
    class: 'step-7',
  },
  labs: {
    route: 'labs',
    text: 'Labs',
    icon: <LabeTubesIcon />,
    isSidebar: true,
    class: 'step-12',
  },
  settings: {
    route: 'settings',
    text: 'Profile settings',
    isSidebar: false,
  },
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '',
        element: <Navigate to={routeDefinitions.dashboard.route!} />,
      },
      {
        path: routeDefinitions.dashboard.route,
        element: <Dashboard />,
      },
      {
        path: routeDefinitions.applications.route,
        element: <Applications />,
      },
      {
        path: routeDefinitions.templates.route,
        element: <Templates />,
      },
      {
        path: routeDefinitions.labs.route,
        element: <Labs />,
      },
      {
        path: routeDefinitions.settings.route,
        element: <Account />,
      },
    ],
  },
  {
    path: '*',
    element: (
      <main className="h-screen w-full flex flex-col justify-center items-center bg-[#1A2238]">
        <h1 className="text-9xl font-extrabold text-white tracking-widest">404</h1>
        <div className="bg-[#FF6A3D] px-2 text-sm rounded rotate-12 absolute">Page Not Found</div>
        <button className="mt-5">
          <a className="relative inline-block text-sm font-medium text-[#FF6A3D] group active:text-orange-500 focus:outline-none focus:ring">
            <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-[#FF6A3D] group-hover:translate-y-0 group-hover:translate-x-0"></span>

            <span className="relative block px-8 py-3 bg-[#1A2238] border border-current">
              <Link to="/">Go Home</Link>
            </span>
          </a>
        </button>
      </main>
    ),
  },
]);

export default router;
