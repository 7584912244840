import React from 'react';

import Profile from './Profile';
import Payments from './Payments';
import Notifications from './Notifications';
import Plans from './Plans';

function Account() {
  return (
    <>
      <div className="flex gap-6 flex-row">
        <div className="basis-1/2">
          <Profile />
        </div>
        <div className="basis-1/2">
          <Notifications />
        </div>
      </div>

      <hr className="my-5" />

      <div className="flex gap-3 flex-row justify-center mb-5">
        <div className="basis-2/3 ">
          <Payments />
        </div>
      </div>
      <div className="flex gap-3 flex-row justify-center">
        <div className="basis-2/3">
          <Plans />
        </div>
      </div>
    </>
  );
}

export default Account;
