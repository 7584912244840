import React from 'react';
import clx from 'classnames';

class LoaderProps {
  public className?: string;
}

export default function Loader({ className }: LoaderProps) {
  return (
    <svg
      className={clx('custom-loader w-20 h-20', className)}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
    >
      <rect fill="#fff" width="3" height="100" transform="translate(0) rotate(180 3 50)">
        <animate
          attributeName="height"
          attributeType="XML"
          dur="0.9s"
          values="30; 100; 30"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="17" fill="#fff" width="3" height="100" transform="translate(0) rotate(180 20 50)">
        <animate
          attributeName="height"
          attributeType="XML"
          dur="1s"
          values="30; 100; 30"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="40" fill="#fff" width="3" height="100" transform="translate(0) rotate(180 40 50)">
        <animate
          attributeName="height"
          attributeType="XML"
          dur="1.1s"
          values="30; 100; 30"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="60" fill="#fff" width="3" height="100" transform="translate(0) rotate(180 58 50)">
        <animate
          attributeName="height"
          attributeType="XML"
          dur="1.3s"
          values="30; 100; 30"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="80" fill="#fff" width="3" height="100" transform="translate(0) rotate(180 76 50)">
        <animate
          attributeName="height"
          attributeType="XML"
          dur="1.2s"
          values="30; 100; 30"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  );
}
