import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Id, Slide, UpdateOptions, toast } from 'react-toastify';

import keycloak from './Keycloak';

const toastConf: UpdateOptions = {
  position: 'top-right',
  hideProgressBar: true,

  autoClose: 2000,
  pauseOnHover: true,
  closeOnClick: true,
  draggable: true,
  transition: Slide,
};

export class BaseSevice {
  client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  constructor() {
    this.client.interceptors.request.use((value) => {
      value.headers.Authorization = `Bearer ${keycloak.token}`;

      return Promise.resolve(value);
    });
  }

  request<TResponse>(
    url: string,
    method: string,
    options?: AxiosRequestConfig<any>,
    data?: any,
  ): Promise<AxiosResponse<TResponse>> {
    let request: Promise<AxiosResponse<TResponse, any>>;
    switch (method) {
      case 'get':
        request = this.client.request<TResponse>({
          url,
          method,
          ...options,
        });
        break;
      case 'post':
      case 'put':
        request = this.client.request<TResponse>({
          url,
          method,
          data,
          ...options,
        });
        break;
      default:
        request = this.client.request<TResponse>({
          url,
          method,
          ...options,
        });
        break;
    }

    let toastId: Id;

    if (method.toLowerCase() !== 'get') toastId = toast.loading('Pending response...');

    return request
      .then((res: AxiosResponse<TResponse>) => {
        if (res && res.status && res.status < 200 && res.status >= 300) {
          throw Error(`Error message ${res.status}: ${res.data}`);
        } else {
          if (res.config.method !== 'get') {
            toast.update(toastId!, {
              render: 'Success',
              type: 'success',
              isLoading: false,
              ...toastConf,
            });
          }
        }

        return res;
      })
      .catch((err: AxiosError) => {
        switch (err?.response?.status) {
          case 400: {
            this.showError(toastId, err, 'Validation error');
            break;
          }
          case 413:
            this.showError(toastId, err, 'File to big');
            break;
          case 429:
            this.showError(toastId, err, "You're out of this operation");
            break;
          default:
            this.showError(
              toastId,
              err,
              (err?.response?.data as string) || 'Something went wrong :)',
            );
            break;
        }

        throw new Error(this.getErrorMessage(err?.response?.data));
      });
  }

  private showError(id: Id, err: AxiosError, message: string) {
    toast.update(id!, {
      render: message,
      type: 'error',
      isLoading: false,
      ...toastConf,
    });
  }

  private getErrorMessage(err: any) {
    if (err) {
      if (typeof err === 'string') return err;
      if (typeof err === 'object') return this.combineError(err);
    }
  }

  private combineError(errorData: any) {
    console.log(errorData);
    const messages = [];
    for (const key in errorData) {
      if (Object.prototype.hasOwnProperty.call(errorData, key)) {
        const message = errorData[key];
        console.log(key, message);
        messages.push(`${key}:${message}`);
      }
    }
    return messages.join(',');
  }
}
