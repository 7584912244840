import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChartBarIcon, PencilIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';

import { routeDefinitions } from '../../../routes';
import { HamburgerButton } from '../atoms/HamburgerButton';
import keycloak, { token$ } from '../../../services/Keycloak';

export function Navbar() {
  const [avatar, setAvatar] = useState('');

  useEffect(() => {
    token$.subscribe((t: any) => {
      setAvatar(t.picture);
    });
  }, []);

  return (
    <nav
      id="nabbar"
      className="fixed top-0 z-50 w-full border-b border-gray-800
			bg-gradient-to-r from-robin-egg-blue-25 to-robin-egg-blue-15 backdrop-blur-20 bg-opacity-10 dark:bg-gray-800 dark:border-gray-700"
    >
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <HamburgerButton />
            <a href="#" className="flex items-center pl-2">
              <img
                className="h-6 mr-3 sm:h-7"
                src="/new icons/android-chrome-512x512.png"
                alt="Your Company"
              />
              <span className="self-center text-xl font-semibold whitespace-nowrap text-white pl-2">
                Pritset
              </span>
            </a>
          </div>
          <div className="flex items-center">
            <div className="flex items-center ml-3">
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">Open user menu</span>
                    <img className="h-8 w-8 rounded-full" src={avatar} alt="" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="cursor-pointer absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {/* <Menu.Item>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => keycloak.accountManagement()}
                        // href={keycloak.createAccountUrl()}
                        className="block px-4 py-2 text-sm text-gray-700"
                      >
                        Account
                      </a>
                    </Menu.Item> */}
                    <Menu.Item>
                      <Link to="/settings" className="block px-4 py-2 text-sm text-gray-700">
                        Settings
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <a
                        onClick={() => keycloak.logout()}
                        className="block px-4 py-2 text-sm text-gray-700"
                      >
                        Sign out
                      </a>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
              <div
                className="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                id="dropdown-user"
              >
                <div className="px-4 py-3" role="none">
                  <p className="text-sm text-gray-900 dark:text-white" role="none">
                    Neil Sims
                  </p>
                  <p
                    className="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                    role="none"
                  >
                    neil.sims@flowbite.com
                  </p>
                </div>
                <ul className="pt-2 pb-4 space-y-1 text-sm text-gray-100">
                  {Object.keys(routeDefinitions).map((rd) => (
                    <li className="rounded-sm" key={rd.toString()}>
                      <Link
                        to={`/${routeDefinitions[rd].route}`}
                        className="flex items-center p-2 space-x-3 rounded-md text-gray-100"
                      >
                        <ChartBarIcon className="h-6 w-6" />
                        <span className="text-gray-100">{routeDefinitions[rd].text}</span>
                      </Link>
                    </li>
                  ))}
                  <li className="rounded-sm">
                    <Link
                      to="/dashboard"
                      className="flex items-center p-2 space-x-3 rounded-md text-gray-100"
                    >
                      <ChartBarIcon className="h-6 w-6" />
                      <span className="text-gray-100">Dashboard</span>
                    </Link>
                  </li>
                  <li className="rounded-sm">
                    <Link
                      to="/dashboard"
                      className="flex items-center p-2 space-x-3 rounded-md text-gray-100"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                        <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                      </svg>
                      <span className="text-gray-100">Applications</span>
                    </Link>
                  </li>
                  <li className="rounded-sm">
                    <Link to="subscriptions" className="flex items-center p-2 space-x-3 rounded-md">
                      <PencilIcon className="h-6 w-6" />
                      <span className="text-gray-100">Subscriptions</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
