import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { BaseSevice } from './BaseService';
import { UserModel } from '../models/user.model';
import { QuotaModel } from '../models/quota.model';
import { PlanModel } from '../models/plan.model';
import keycloak, { token$ } from './Keycloak';
import { IUserSecrets } from '../models/user-secrets.model';

export const user$ = new ReplaySubject<UserModel>();
export const plan$ = new ReplaySubject<PlanModel>();
export const getsStatistics$ = new BehaviorSubject(undefined);

setInterval(() => getsStatistics$.next(undefined), 60000);

class UserService extends BaseSevice {
  apiUrl = 'api/user';

  loadUser() {
    this.request<UserModel>(`${this.apiUrl}/current`, 'get').then((res) => {
      user$.next(res.data);
      plan$.next(res.data.plan!);
      token$.subscribe((t: any) => {
        if (!t.picture) keycloak.login();
      });
    });
  }

  getUserStatistics() {
    return this.request<QuotaModel>(`${this.apiUrl}/statistics`, 'get').then((res) => {
      return res.data;
    });
  }

  updateKeycloakUser(id: string, user: any) {
    return this.request(`${this.apiUrl}/${id}/keycloak`, 'put', undefined, user).then(
      (res) => res.data,
    );
  }

  getAccessToken() {
    return this.request(`${this.apiUrl}/token`, 'get').then((res) => res.data as IUserSecrets);
  }

  resetAccessToken() {
    return this.request(`${this.apiUrl}/token`, 'put').then((res) => res.data as IUserSecrets);
  }
}

export const userService = new UserService();
