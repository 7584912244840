import Keycloak from 'keycloak-js';
import { ReplaySubject } from 'rxjs';

const keycloak = new Keycloak({
  url: process.env.REACT_APP_AUTH_URL,
  realm: process.env.REACT_APP_REALM as string,
  clientId: process.env.REACT_APP_CLIENT_ID as string,
});

keycloak.onTokenExpired = () => {
  keycloak.updateToken(3600).then(() => {
    console.log('token refreshed');
    token$.next(keycloak.tokenParsed);
  });
};

keycloak.onAuthSuccess = () => {
  token$.next(keycloak.tokenParsed);
};

export const token$ = new ReplaySubject();
export default keycloak;
