import React, { useState } from 'react';
import { useTestWebhookUrl } from '../../../hooks/labs/mutations';
import { WebhookModel } from '../../../models/webhook.model';
import { WebhookTypeEnum } from '../../../enums/webhook-type.enum';
import { Select } from 'flowbite-react';

export default function Labs() {
  const [webhook, setWebhook] = useState<WebhookModel>({ type: WebhookTypeEnum.Template, url: '' });
  const testWebhook = useTestWebhookUrl();

  const testWebhookUrl = async () => {
    await testWebhook.mutateAsync(webhook);
  };

  return (
    <div>
      <h2 className="text-white text-xl text-bold mb-5 font-bold">Webhook tester</h2>
      <div className="w-full text-sm mb-5 step-13">
        <div className="flex">
          <div className="pr-5" style={{ width: '10%' }}>
            <Select onChange={(ev) => setWebhook({ ...webhook, type: Number(ev.target.value) })}>
              <option value={WebhookTypeEnum.Template}>Template</option>
              <option value={WebhookTypeEnum.Image}>Image</option>
            </Select>
          </div>
          <div className="w-full pr-5">
            <input
              type="url"
              placeholder="Webhook URL"
              onChange={(ev) => setWebhook({ ...webhook, url: ev.target.value })}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:text-white"
            />
          </div>
          <div>
            <button
              type="button"
              onClick={() => testWebhookUrl()}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Test
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
