import React from 'react';

export function HamburgerButton() {
  return (
    <button
      data-drawer-target='logo-sidebar'
      data-drawer-toggle='logo-sidebar'
      aria-controls='logo-sidebar'
      type='button'
      className='inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-robin-egg-blue-15 focus:outline-none focus:ring-2 focus:ring-robin-egg-blue-25 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'
    >
      <span className='sr-only'>Open sidebar</span>
      <svg
        width='56'
        height='50'
        viewBox='0 0 56 50'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g filter='url(#filter0_bdi_70_790)'>
          <path
            d='M12 36C11.4333 36 10.958 35.808 10.574 35.424C10.19 35.04 9.99867 34.5653 10 34C10 33.4333 10.192 32.958 10.576 32.574C10.96 32.19 11.4347 31.9987 12 32H44C44.5667 32 45.042 32.192 45.426 32.576C45.81 32.96 46.0013 33.4347 46 34C46 34.5667 45.808 35.042 45.424 35.426C45.04 35.81 44.5653 36.0013 44 36H12ZM12 26C11.4333 26 10.958 25.808 10.574 25.424C10.19 25.04 9.99867 24.5653 10 24C10 23.4333 10.192 22.958 10.576 22.574C10.96 22.19 11.4347 21.9987 12 22H44C44.5667 22 45.042 22.192 45.426 22.576C45.81 22.96 46.0013 23.4347 46 24C46 24.5667 45.808 25.042 45.424 25.426C45.04 25.81 44.5653 26.0013 44 26H12ZM12 16C11.4333 16 10.958 15.808 10.574 15.424C10.19 15.04 9.99867 14.5653 10 14C10 13.4333 10.192 12.958 10.576 12.574C10.96 12.19 11.4347 11.9987 12 12H44C44.5667 12 45.042 12.192 45.426 12.576C45.81 12.96 46.0013 13.4347 46 14C46 14.5667 45.808 15.042 45.424 15.426C45.04 15.81 44.5653 16.0013 44 16H12Z'
            fill='#4ECDC4'
            fillOpacity='0.5'
          />
        </g>
        <defs>
          <filter
            id='filter0_bdi_70_790'
            x='-6'
            y='-10'
            width='68'
            height='72'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feGaussianBlur in='BackgroundImageFix' stdDeviation='5' />
            <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_70_790' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='4' />
            <feGaussianBlur stdDeviation='5' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
            <feBlend
              mode='normal'
              in2='effect1_backgroundBlur_70_790'
              result='effect2_dropShadow_70_790'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect2_dropShadow_70_790'
              result='shape'
            />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='4' />
            <feGaussianBlur stdDeviation='2.5' />
            <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
            <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0' />
            <feBlend mode='normal' in2='shape' result='effect3_innerShadow_70_790' />
          </filter>
        </defs>
      </svg>
    </button>
  );
}
