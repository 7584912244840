import React, { useEffect, useState } from 'react';

import { TextInput, Button } from 'flowbite-react';
import { toast } from 'react-toastify';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { userService } from '../../../../services/UserService';
import { IUserSecrets } from '../../../../models/user-secrets.model';
import { useNavigate } from 'react-router-dom';

const hidenSecretString = '•••••••••••••••••••••';

export default function Notifications() {
  const [showAccessToken, setShowAccessToken] = useState(false);
  const [showUserSecret, setShowUserSecret] = useState(false);
  const [userSecrets, setUserSecrets] = useState<IUserSecrets>();
  const navigate = useNavigate();

  useEffect(() => {
    userService.getAccessToken().then((userSecrets) => setUserSecrets(userSecrets));
  }, []);

  const copyAccessToken = (token: string) => {
    navigator.clipboard
      .writeText(token)
      .then(() => {
        toast.success('Copied access token to clipboard');
      })
      .catch(() => {
        toast.error('Unable copy to clipboard');
      });
  };

  const copyUserSecret = (token: string) => {
    navigator.clipboard
      .writeText(token)
      .then(() => {
        toast.success('Copied secret to clipboard');
      })
      .catch(() => {
        toast.error('Unable copy to clipboard');
      });
  };

  const resetAccessToken = () => {
    userService.resetAccessToken().then((token) => setUserSecrets(token));
  };

  const resetTour = () => {
    localStorage.removeItem('tour');
    navigate('/');
  };

  return (
    <>
      <h2 className="text-white text-2xl text-bold mb-5 font-bold">Additional settings</h2>

      <form className="w-full text-white">
        <div className="flex flex-col gap-4">
          <div className="w-full">
            <Button onClick={resetTour}>Restart tour</Button>
          </div>
          {/* <div className="flex items-center gap-2">
            <Checkbox
              id="accept"
              className="w-5 h-5 disabled:bg-gray-600"
              defaultChecked
              disabled
            />
            <Label htmlFor="accept" className="flex text-xl text-white uppercase">
              Security notifications
            </Label>
          </div>
          <div className="flex items-center gap-2">
            <Checkbox
              id="promotion"
              className="w-5 h-5 disabled:bg-gray-600"
              defaultChecked
              disabled
            />
            <Label className="text-white text-xl uppercase">Payment operations</Label>
          </div>
          <div className="flex items-center gap-2">
            <Checkbox id="age" className="w-5 h-5 disabled:bg-gray-600" defaultChecked disabled />
            <Label className="text-white text-xl uppercase">Plan prolonging</Label>
          </div> */}
          {/* <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full px-3 mt-6 mb-6 md:mb-0">
              <Button type="submit" color="success" className="w-full" size="lg">
                Save
              </Button>
            </div>
          </div> */}
        </div>
      </form>

      <h2 className="text-white text-2xl text-bold mb-5 mt-5 font-bold">Security settings</h2>

      <div className="w-full flex-col space-y-5">
        <h2 className="text-white text-xl text-bold mb-5 font-bold mt-5">Access Token</h2>
        <div className="w-full flex gap-5">
          <div className="flex flex-auto">
            <TextInput
              value={showAccessToken ? userSecrets?.accessToken : hidenSecretString}
              disabled
              className="w-full"
            />
          </div>
          <div className="flex">
            <Button onClick={() => setShowAccessToken(!showAccessToken)}>
              {showAccessToken ? (
                <EyeIcon className="h-6 w-6 text-white" />
              ) : (
                <EyeSlashIcon className="h-6 w-6 text-white" />
              )}
            </Button>
          </div>
          <div className="flex">
            <Button onClick={() => copyAccessToken(userSecrets?.accessToken || '')}>Copy</Button>
          </div>
        </div>

        <h2 className="text-white text-xl text-bold mb-5 font-bold mt-5">User secret</h2>
        <div className="w-full flex gap-5">
          <div className="flex flex-auto">
            <TextInput
              value={showUserSecret ? userSecrets?.secret : hidenSecretString}
              disabled
              className="w-full"
            />
          </div>
          <div className="flex">
            <Button onClick={() => setShowUserSecret(!showUserSecret)}>
              {showUserSecret ? (
                <EyeIcon className="h-6 w-6 text-white" />
              ) : (
                <EyeSlashIcon className="h-6 w-6 text-white" />
              )}
            </Button>
          </div>
          <div className="flex">
            <Button onClick={() => copyUserSecret(userSecrets?.secret || '')}>Copy</Button>
          </div>
        </div>

        <div className="w-full flex justify-end">
          <div className="flex">
            <Button onClick={() => resetAccessToken()}>Reset secrets</Button>
          </div>
        </div>
      </div>
    </>
  );
}
