import React, { useEffect, useState } from 'react';

import SPPieChart from '../../SPPieChart';
import { userService, plan$, getsStatistics$ } from '../../../services/UserService';
import { PlanModel } from '../../../models/plan.model';
import { QuotaModel } from '../../../models/quota.model';
import Loader from '../icons/loader';
import { useTour } from '@reactour/tour';
import { Button, Modal } from 'flowbite-react';

class Statistics {
  public additionalRequests?: number;
  public actualRequests?: number;
  public templateCount?: number;
  public applicationCount?: number;
  public concurrentProcessingsCount?: number;
}

function Dashboard() {
  const [plan, setPlan] = useState<PlanModel>({});
  const [statistics, setStatistics] = useState<Statistics>({});
  const [userLoaded, setUserLoaded] = useState<boolean>(false);
  const [showStartTourModal, setShowStartTourModal] = useState(false);
  const tour = useTour();

  useEffect(() => {
    const subs: any = {};
    subs.planSub = plan$.subscribe((newPlan) => {
      setPlan(newPlan);
      setUserLoaded(true);
      subs.statSub = getsStatistics$.subscribe(() => {
        userService.getUserStatistics().then((quota: QuotaModel) => {
          const additional = newPlan.maxRequestCount! - quota.totalRequestCount!;
          if (additional < 0) {
            setStatistics({
              actualRequests: newPlan.maxRequestCount,
              additionalRequests: Math.abs(additional),
              applicationCount: quota.applicationCount,
              templateCount: quota.templateCount,
              concurrentProcessingsCount: newPlan.maxConcurrentProcessingsCount,
            });
          } else {
            setStatistics({
              actualRequests: additional,
              additionalRequests: 0,
              applicationCount: quota.applicationCount,
              templateCount: quota.templateCount,
              concurrentProcessingsCount: newPlan.maxConcurrentProcessingsCount,
            });
          }
        });
      });
    });

    if (!localStorage.getItem('tour')) setShowStartTourModal(true);

    return () => {
      subs.planSub.unsubscribe();
      subs.statSub.unsubscribe();
    };
  }, []);

  const startTour = () => {
    tour.setIsOpen(true);
    setShowStartTourModal(false);
  };

  const cancelTour = () => {
    localStorage.setItem('tour', 'done');
    setShowStartTourModal(false);
  };

  return (
    <>
      {userLoaded == true ? (
        <div className="grid grid-cols-3 gap-6 mb-6 step-1">
          <div className="w-full px-4 py-5 text-center">
            <div className="flex justify-center">
              <SPPieChart
                maxValue={plan?.maxRequestCount || 0}
                value={statistics.actualRequests || 0}
              ></SPPieChart>
            </div>
            <div className="mt-1 text-3xl font-semibold text-white">
              Total requests {statistics.actualRequests || 0} / {plan?.maxRequestCount || 0}
            </div>
          </div>
          <div className="w-full px-4 py-5">
            <div className="flex mt-1 text-3xl font-semibold text-white">
              <div>
                <h2>Statistics</h2>
                <ul className="list-none mt-10 test-md">
                  <li className="mb-1">
                    Templates: {statistics.templateCount}/{plan.maxTemplateCount}
                  </li>
                  <li className="mb-1">
                    Applications: {statistics.applicationCount}/{plan.maxApplicationCount}
                  </li>
                  <li className="mb-1">
                    Concurrent processors: {statistics.concurrentProcessingsCount}
                  </li>
                  <li className="mb-1">Additional requests: {statistics.additionalRequests}</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="w-full px-4 py-5 rounded-lg shadow text-center text-white bg-emerald-600">
            <div className="mt-1 text-3xl font-semibold">Requests total:</div>
            <div className="mt-1 text-3xl font-semibold">{user.quota?.totalRequestCount || 0}</div>
          </div>

          <div className="w-full px-4 py-5 rounded-lg shadow text-center text-white bg-emerald-600">
            <div className="mt-1 text-3xl font-semibold">Images total:</div>
            <div className="mt-1 text-3xl font-semibold">{user.quota?.imageCount || 0}</div>
          </div>

          <div className="w-full px-4 py-5 rounded-lg shadow text-center text-white bg-emerald-600">
            <div className="mt-1 text-3xl font-semibold">Upgrade subscription</div>
          </div> */}
        </div>
      ) : (
        <div className="flex flex-wrap gap-2 justify-center place-content-center h-60 bg-gray-300/50">
          <div className="text-center size-10">
            <Loader className="w-20 h-20" />
          </div>
        </div>
      )}

      <Modal show={showStartTourModal} className="pritset-modal">
        <Modal.Header>
          <div>
            <div className="text-2xl text-white">Tour</div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: 'center' }}>
            <h2 className="text-2xl">Do you want to start tour?</h2>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-between w-full">
            <div>
              <Button size="xl" onClick={() => startTour()}>
                <p className="text-2xl">Start tour</p>
              </Button>
            </div>
            <div>
              <Button
                size="xl"
                color="gray"
                onClick={() => {
                  cancelTour();
                }}
              >
                <p className="text-2xl">Nope</p>
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* <div className="grid grid-cols-2 gap-6 mb-6">
        <div className="w-full px-4 py-5 rounded-lg shadow text-center text-white bg-emerald-600">
          <div className="mt-1 text-3xl font-semibold">Top 3 Templates:</div>
          <div className="mt-1 text-left text-xl font-semibold">
            <ol>
              <li>sdfjklghsdfglhskdfjg</li>
              <li>12341252345654</li>
              <li>asqqaaaaaaaaaaaaaaa</li>
            </ol>
          </div>
        </div>

        <div className="w-full px-4 py-5 rounded-lg shadow text-center text-white bg-emerald-600">
          <div className="mt-1 text-3xl font-semibold">Top 3 Application:</div>
          <div className="mt-1 text-left text-xl font-semibold">
            <ol>
              <li>sdfjklghsdfglhskdfjg</li>
              <li>12341252345654</li>
              <li>asqqaaaaaaaaaaaaaaa</li>
            </ol>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Dashboard;
