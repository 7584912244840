import React from 'react';

class LubeTubes {
  public className?: string;
}

export default function LabeTubesIcon({ className }: LubeTubes) {
  return (
    <svg
      viewBox="0 0 474 491"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-6 w-6'}
    >
      <path
        d="M120.001 85.9975H286.001M120.001 85.9975C120.001 85.9975 107.501 85.9975 107.501 73.7475C107.501 61.4975 120.001 61.4975 120.001 61.4975H286.001C286.001 61.4975 300.001 64.4975 300.001 73.7475C300.001 82.9975 286.001 85.9975 286.001 85.9975M120.001 85.9975V236.498C120.001 236.498 100.592 266.39 78.0068 304.497M286.001 85.9975V236.498C286.001 236.498 313.331 280.273 339.387 329.497M78.0068 304.497H167M78.0068 304.497C69.6636 318.574 60.8868 333.772 52.4947 348.997M52.4947 348.997H167M52.4947 348.997C44.3323 363.806 36.5339 378.641 29.8521 392.497M29.8521 392.497C13.778 425.83 4.16677 453.497 11.5001 461.497C31.9062 483.758 310.26 489.243 371.779 467.177M29.8521 392.497H167M322.001 84.4975H442.001M322.001 84.4975C322.001 84.4975 300.001 84.4975 300.001 72.9975C300.001 61.4975 322.001 61.4975 322.001 61.4975H382.001H442.001C442.001 61.4975 464.501 61.4975 464.501 72.9975C464.501 84.4975 442.001 84.4975 442.001 84.4975M322.001 84.4975V203.498M442.001 84.4975V436.997C442.001 436.997 442.001 467.497 382.001 467.497C378.38 467.497 374.977 467.386 371.779 467.177M322.001 203.498H382.001M322.001 203.498V264.498M322.001 264.498H382.001M322.001 264.498V296.997M382.001 329.497H339.387M371.779 467.177C376.585 465.454 380.067 463.562 382 461.497C396.088 446.449 368.535 384.563 339.387 329.497M367.5 142.999C367.5 118.499 411 117.499 411 142.999C411 168.499 367.5 167.499 367.5 142.999ZM218.5 113.998C218.5 89.4975 262 88.4975 262 113.998C262 139.498 218.5 138.498 218.5 113.998ZM151.5 41.9989C151.5 17.4989 195 16.4989 195 41.9989C195 67.4989 151.5 66.4989 151.5 41.9989ZM158 172.999C158 148.499 201.5 147.499 201.5 172.999C201.5 198.499 158 197.499 158 172.999ZM324 41.9989C324 17.4989 367.5 16.4989 367.5 41.9989C367.5 67.4989 324 66.4989 324 41.9989ZM416.5 28.5C416.5 4 460 3 460 28.5C460 54 416.5 53 416.5 28.5Z"
        stroke="white"
        strokeWidth="18"
      />
    </svg>
  );
}
