import React from 'react';

import { Outlet, useLocation } from 'react-router-dom';
import { routeDefinitions } from '../routes';

function Content() {
  const localLocation = useLocation();
  const cleanRoute =
    localLocation.pathname.split('/').filter((el) => el)[0] || localLocation.pathname;
  const routeDefinition = routeDefinitions[cleanRoute];

  return (
    <div className="p-4 sm:ml-64">
      <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
        <h1 className="text-white text-2xl text-bold mb-5 font-bold">{routeDefinition.text}</h1>
        <hr />
        <br />
        <Outlet />
      </div>
    </div>
  );
}

export default Content;
