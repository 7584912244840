import React from 'react';

class ErrorsSummeryProps {
  errors: string[] = [];
}

export function ErrorsSummery({ errors }: ErrorsSummeryProps) {
  return (
    <div
      className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
      role="alert"
    >
      <ul>
        {errors.map((x, index) => (
          <>
            <span key={index} className="font-medium">
              Info alert!
            </span>
            {x} <br />
          </>
        ))}
      </ul>
    </div>
  );
}
