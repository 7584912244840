import React, { useState, useEffect } from 'react';
import { Button } from 'flowbite-react';

import paymentService from '../../../services/PaymentService';
import { CreatePaymentModel } from '../../../models/create-payment.model';

class PaymentButtonProps {
  public product?: CreatePaymentModel;
}

export function PaymentButton(props: PaymentButtonProps) {
  const [isReady, setIsReady] = useState(false);
  const [paymentButtonState, setPaymentButtonState] = useState<{
    invoiceId: string;
    pageUrl: string;
  }>();

  useEffect(() => {
    paymentService.createMono(props.product!).then((res) => {
      setPaymentButtonState(res.data as any);
      setIsReady(!isReady);
    });
  }, [props.product]);

  return (
    <Button
      type="submit"
      isProcessing={!isReady}
      disabled={!isReady}
      color="success"
      onClick={() => location.assign(paymentButtonState?.pageUrl!)}
    >
      Pay
    </Button>
  );
}
