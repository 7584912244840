import { StepType } from '@reactour/tour';

export const tourSteps: StepType[] = [
  {
    selector: '.step-1',
    content: 'Here you can check progress of Pritset usage',
  },
  {
    selector: '.step-2',
    content: 'First lets go to applications tab',
  },
  {
    selector: '.step-3',
    content:
      'Here you can manage your applications. These applications are used in ' +
      'future API processing requests and in will be used for statistics',
  },
  {
    selector: '.step-4',
    content: 'This id is unique and being used in API processing requests',
  },
  {
    selector: '.step-5',
    content: 'Click to add new application',
  },
  {
    selector: '.step-6',
    content: 'Pass Application name and press Save',
  },
  {
    selector: '.step-3',
    content:
      'Now you have additional unique application which you can use for your different projects to collect statistics',
  },
  {
    selector: '.step-7',
    content: 'Now lets go to templates page',
  },
  {
    selector: '.step-8',
    content: 'Here you can manage templates which are used in API template processings',
  },
  {
    selector: '.step-9',
    content: 'This is also unique id which exactly for API template processings',
  },
  {
    selector: '.step-10',
    content: 'Now lets got and create new template',
  },
  {
    selector: '.step-11',
    content: 'Pass Template name, some tages if needed, template file and press Save',
  },
  {
    selector: '.step-8',
    content: 'Now you have template to use for API template processing',
  },
  {
    selector: '.step-12',
    content: 'Lets continue exploring to labs',
  },
  {
    selector: '.step-13',
    content:
      'In the dropdown you can select Template or Image depends on which you well' +
      ' receive dummy image response eather dummy template reponse to endpoint you pass' +
      ' to the input next to the dropdown after press Test',
  },
];
