import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { templateService } from '../../services/TemplateService';

export const useGetTemplates = (q: any, options?: UseQueryOptions<any, any, any, any>) => {
  return useQuery<any>({
    queryKey: ['templates'],
    queryFn: async () => templateService.getFiltered(q),
    ...options,
  });
};
