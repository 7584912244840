import { WebhookModel } from '../models/webhook.model';
import { BaseSevice } from './BaseService';

class LabsService extends BaseSevice {
  apiUrl = 'api/labs';

  testWebhook(model: WebhookModel) {
    return this.request(`${this.apiUrl}/webhook`, 'post', undefined, model);
  }
}

export const labsService = new LabsService();
