import { FilterResult } from '../models/filtering/filter-result';
import { PaymentModel } from '../models/payment-model';
import { CreatePaymentModel } from '../models/create-payment.model';
import { PaymentFormData } from '../models/pyament-form-data';
import { BaseSevice } from './BaseService';

class PaymentService extends BaseSevice {
  apiUrl = 'api/payment';

  getFiltered(q?: any) {
    return this.request<FilterResult<PaymentModel>>(
      `${this.apiUrl}`,
      'get',
      {
        params: { q },
      },
      null,
    ).then((res) => {
      return res.data;
    });
  }

  create(planId: number) {
    if (!planId) {
      throw new Error('Plan id should have value');
    }

    return this.request<PaymentFormData>(`${this.apiUrl}`, 'post', undefined, {
      subscriptionId: planId,
    });
  }

  createMono(product: CreatePaymentModel) {
    if (!product.value) {
      throw new Error('Product and value required');
    }

    return this.request<PaymentFormData>(`${this.apiUrl}/mono`, 'post', undefined, product);
  }
}

const paymentService = new PaymentService();

export default paymentService;
