import React, { useEffect, useState } from 'react';
import { Button, Label, TextInput } from 'flowbite-react';

import { token$ } from '../../../../services/Keycloak';
import { userService } from '../../../../services/UserService';

class UserState {
  public id?: string;
  public email?: string;
  public firstName?: string;
  public lastName?: string;
}

export default function Profile() {
  const [user, setUser] = useState<UserState>();

  useEffect(() => {
    token$.subscribe((t: any) => {
      setUser({
        id: t.sub,
        email: t.email,
        firstName: t.given_name,
        lastName: t.family_name,
      });
    });
  }, []);

  const onSubmit = (e: any) => {
    console.log('submiting profile');
    e.preventDefault();

    const newUser = {
      email: e.target['email'].value,
      firstName: e.target['firstName'].value,
      lastName: e.target['lastName'].value,
    };

    userService.updateKeycloakUser(user?.id!, newUser).then(() => {
      setUser(newUser);
    });
  };

  return (
    <div className="text-white rounded">
      <h2 className="text-white text-2xl text-bold mb-5 font-bold">Profile</h2>
      {user && (
        <form className="w-full" onSubmit={(e) => onSubmit(e)}>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <div className="mb-2 block">
                <Label value="First name" className="text-white uppercase" />
              </div>
              <TextInput
                sizing="lg"
                type="text"
                name="firstName"
                defaultValue={user.firstName}
                placeholder="Jane"
              />
              {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
            </div>
            <div className="w-full md:w-1/2 px-3">
              <div className="mb-2 block">
                <Label value="Last Name" className="text-white uppercase" />
              </div>
              <TextInput
                sizing="lg"
                type="text"
                name="lastName"
                defaultValue={user.lastName}
                placeholder="Jane"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <div className="mb-2 block">
                <Label value="Email" className="text-white uppercase" />
              </div>
              <TextInput
                sizing="lg"
                type="email"
                name="email"
                defaultValue={user.email}
                placeholder="name@example-email.com"
                disabled
              />
              {/* <p className="text-gray-600 text-xs italic">
                Make it as long and as crazy as you&apos;d like
              </p> */}
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full px-3 mt-6 mb-6 md:mb-0">
              <Button type="submit" color="success" className="bg-green-500 w-full" size="lg">
                Save
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
