import React from 'react';

import ReactDOM from 'react-dom/client';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import keycloak from './services/Keycloak';
import { TourProvider, useTour } from '@reactour/tour';
import { tourSteps } from './common/steps';
import { Button } from 'flowbite-react';

const queryClient = new QueryClient();

keycloak
  .init({
    onLoad: 'login-required',
    checkLoginIframe: false,
  })
  .then((authenticated: boolean) => {
    if (authenticated) {
      const root = ReactDOM.createRoot(document.getElementById('root')!);
      root.render(
        // <React.StrictMode>
        <TourProvider
          steps={tourSteps}
          showDots={false}
          showBadge={false}
          styles={{
            popover: (base) => ({
              ...base,
              borderRadius: '5px',
            }),
          }}
          nextButton={({ currentStep, setCurrentStep, stepsLength }) => {
            const tour = useTour();

            return (
              <>
                {stepsLength - 1 !== currentStep ? (
                  <Button onClick={() => setCurrentStep(currentStep + 1)}>Next</Button>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        localStorage.setItem('tour', 'done');
                        tour.setIsOpen(false);
                      }}
                    >
                      Finish
                    </Button>
                  </>
                )}
              </>
            );
          }}
          prevButton={({ currentStep, setCurrentStep }) => {
            return (
              <Button disabled={currentStep === 0} onClick={() => setCurrentStep(currentStep - 1)}>
                Prev
              </Button>
            );
          }}
          disableKeyboardNavigation={true}
          defaultOpen={false}
          onClickClose={(clickProps) => {
            localStorage.setItem('tour', 'done');
            clickProps.setIsOpen(false);
          }}
        >
          <QueryClientProvider client={queryClient}>
            <App />
            <ToastContainer
              position="top-right"
              hideProgressBar={true}
              autoClose={2000}
              newestOnTop={true}
              pauseOnHover
              closeOnClick
              draggable
              transition={Slide}
            />
          </QueryClientProvider>
        </TourProvider>,
        // </React.StrictMode>,
      );
    } else {
      keycloak.login({
        scope: 'audience',
        redirectUri: document.location.href,
      });
    }
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
