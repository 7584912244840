import React, { useState, useRef, Fragment, useEffect, FormEvent } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { PlusIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import applicationService from '../../../services/ApplicationService';
import { ApplicationModel } from '../../../models/application.model';
import { ErrorsSummery } from '../atoms/ErrorsSummery';
import { getsStatistics$ } from '../../../services/UserService';
import { Button, Table } from 'flowbite-react';
import Loader from '../icons/loader';
import { useTour } from '@reactour/tour';

interface HTMLFormElementExtended extends HTMLFormElement {
  getValues<T>(): T;
  getValues(): any;
}

HTMLFormElement.prototype.getValues = function () {
  console.log('getValues function: ', this);
  const obj: any = {};

  for (let i = 0; i < this.length; i++) {
    const formElement = this[i] as HTMLInputElement;

    if (formElement.type === 'file') obj[formElement.name] = formElement.files;
    if (formElement.type === 'text') obj[formElement.name] = formElement.value;
  }

  return obj;
};

class ModalsState {
  public showApplicationModal?: boolean;
  public showConfirmModal?: boolean;
}

class ApplicationsState {
  public isApplicationEditing?: boolean;
  public editingApplication?: ApplicationModel;
  public deletingApplication?: ApplicationModel;
}

function Applications() {
  const cancelApplicationButtonRef = useRef(null);
  const cancelConfirmButtonRef = useRef(null);
  const tour = useTour();

  const [disabled, setDisabled] = useState<boolean>();

  const [modals, setModals] = useState<ModalsState>({
    showApplicationModal: false,
    showConfirmModal: false,
  });

  const [applications, setApplications] = useState<ApplicationModel[]>([]);
  const [applicationsLoading, setApplicationsLoading] = useState(false);

  const [state, setState] = useState<ApplicationsState>({
    isApplicationEditing: false,
    editingApplication: undefined,
  });

  const [generatingSecretKey, setGeneratingSecretKey] = useState(false);

  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    loadApplications();
  }, []);

  const selectApplicationToEdit = (application: ApplicationModel) => {
    setState({
      ...state,
      isApplicationEditing: true,
      editingApplication: application,
    });

    setModals({
      ...modals,
      showApplicationModal: true,
    });
  };

  const closeApplicationModal = () => {
    setState({
      ...state,
      isApplicationEditing: false,
      editingApplication: undefined,
    });

    setModals({
      ...modals,
      showApplicationModal: false,
    });
  };

  const loadApplications = () => {
    setApplicationsLoading(true);
    applicationService.getFiltered().then((data) => {
      setApplications(data.data);
      setState({ ...state, deletingApplication: undefined });
      setApplicationsLoading(false);
    });
  };

  const showConfirmationModal = (model?: ApplicationModel) => {
    setModals({ ...modals, showConfirmModal: true });
    setState({ ...state, deletingApplication: model });
  };

  const closeConfirmationModal = () => {
    setModals({ ...modals, showConfirmModal: false });
    setState({ ...state, deletingApplication: undefined });
  };

  const deleteApplication = (id: string) => {
    setDisabled(true);
    applicationService
      .delete(id)
      .then(() => {
        setModals({ ...modals, showConfirmModal: false });
        loadApplications();
      })
      .finally(() => {
        setDisabled(false);
        getsStatistics$.next(undefined);
      });
  };

  const saveApplcation = (applicationModel: ApplicationModel) => {
    let promise: Promise<AxiosResponse<ApplicationModel>>;
    if (state.isApplicationEditing) {
      promise = applicationService.update(state.editingApplication?.id || '', applicationModel);
    } else {
      promise = applicationService.create(applicationModel);
    }

    setDisabled(true);
    promise
      .then(() => {
        setState({
          ...state,
          isApplicationEditing: false,
          editingApplication: undefined,
        });

        setModals({
          showConfirmModal: false,
          showApplicationModal: false,
        });

        getsStatistics$.next(undefined);

        loadApplications();
      })
      .finally(() => setDisabled(false));

    return promise;
  };

  const formSubmitHandler = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    var form = ev.target as HTMLFormElementExtended;
    const application = form.getValues<ApplicationModel>();
    setErrors(validateForm(application));
    if (!errors.length) {
      saveApplcation(application).then(() => {
        nextStep();
      });
    }
  };

  const validateForm = (template: ApplicationModel) => {
    const localErrors = [];
    if (!template.name || !template.name.length || template.name.length < 5)
      localErrors.push('Name cannot be empty or less then 5 characters');

    return localErrors;
  };

  const copyApplicationKey = (application: ApplicationModel) => {
    navigator.clipboard
      .writeText(application.secretKey!)
      .then(() => {
        toast.success('Copied application key to clipboard');
      })
      .catch(() => {
        toast.error('Unable copy to clipboard');
      });
  };

  const regenerateApplicationKey = (applicationId: string) => {
    setGeneratingSecretKey(!generatingSecretKey);
    applicationService.regenerateKey(applicationId).then((res) => {
      const updated = [...applications];
      const foundObj = applications.find((x) => x.id == applicationId);
      if (foundObj) {
        foundObj.secretKey = res.data;
        setApplications(updated);
      }
      setGeneratingSecretKey(!generatingSecretKey);
    });
  };

  const nextStep = () => {
    setTimeout(() => {
      tour.setCurrentStep(tour.currentStep + 1);
    }, 200);
  };

  return (
    <div className="step-3">
      <div className="w-full text-sm mb-5">
        <div className="flex">
          <div className="w-full pr-5">
            <input
              type="search"
              name="Search"
              placeholder="Search..."
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:text-white"
            />
          </div>
          <div className="">
            <button
              type="button"
              onClick={() => {
                setModals({ ...modals, showApplicationModal: true });
                nextStep();
              }}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 step-5"
            >
              <PlusIcon className="h-6 w-6"></PlusIcon>
            </button>
          </div>
        </div>
      </div>
      {applicationsLoading ? (
        <div className="flex flex-wrap gap-2 justify-center place-content-center h-60 bg-gray-300/50">
          <div className="text-center size-10">
            <Loader className="w-20 h-20" />
          </div>
        </div>
      ) : (
        <Table className="table-auto">
          <Table.Head>
            <Table.HeadCell>Application name</Table.HeadCell>
            <Table.HeadCell>Id</Table.HeadCell>
            <Table.HeadCell>Secret Key</Table.HeadCell>
            <Table.HeadCell></Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {applications?.length ? (
              applications.map((x, index) => (
                <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell>{x.name}</Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white step-4">
                    {x.id}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      type="submit"
                      className="bg-green-600 disabled:bg-gray-600"
                      onClick={() => copyApplicationKey(x)}
                      disabled={generatingSecretKey}
                      isProcessing={generatingSecretKey}
                    >
                      Copy to Clipboard
                    </Button>
                  </Table.Cell>
                  <Table.Cell className="px-6 py-4 text-right">
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="mr-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                          <EllipsisHorizontalIcon className="h-6 w-6"></EllipsisHorizontalIcon>
                        </Menu.Button>
                      </div>
                      <Menu.Items className="cursor-pointer text-left absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {() => (
                            <span
                              onClick={() => selectApplicationToEdit(x)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-sky-700 hover:text-[#ffffff]"
                            >
                              Edit
                            </span>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {() => (
                            <span
                              onClick={() => regenerateApplicationKey(x.id!)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-sky-700 hover:text-[#ffffff]"
                            >
                              Regenerate key
                            </span>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {() => (
                            <span
                              onClick={() => showConfirmationModal(x)}
                              className="block px-4 py-2 text-sm text-red-900 font-bold hover:bg-sky-500"
                            >
                              Delete
                            </span>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Menu>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell colSpan={4} className="px-6 py-4 text-center text-xl">
                  No applications found
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      )}

      <Transition.Root show={modals.showApplicationModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelApplicationButtonRef}
          onClose={() => closeApplicationModal()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="bg-[#4ECDC4] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-8 step-6">
                  <h1 className="text-2xl">
                    Application {state.isApplicationEditing ? 'editing' : 'creation'}
                  </h1>
                  <br />
                  <form className="w-full max-w-lg" onSubmit={(ev) => formSubmitHandler(ev)}>
                    {state.isApplicationEditing ? (
                      <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            Applicaiton Id
                          </label>
                          <input
                            name="id"
                            className="appearance-none block w-full border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            type="text"
                            disabled
                            defaultValue={state.editingApplication?.id}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          Name <small>should have at least 5 leters</small>
                        </label>
                        <input
                          className="appearance-none block w-full border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          name="name"
                          type="text"
                          minLength={5}
                          defaultValue={state.editingApplication?.name}
                          placeholder="Type in application name, aka 'Customer order'"
                        />
                      </div>
                    </div>

                    {errors.length ? <ErrorsSummery errors={errors} /> : ''}

                    <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
                        disabled={disabled}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => closeApplicationModal()}
                        ref={cancelApplicationButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={modals.showConfirmModal && state.deletingApplication !== undefined}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelConfirmButtonRef}
          onClose={() => closeConfirmationModal()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="bg-[#4ECDC4] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-8">
                  <button
                    type="button"
                    className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    ref={cancelConfirmButtonRef}
                    onClick={() => setModals({ ...modals, showConfirmModal: false })}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                  <svg
                    className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <p className="mb-4 text-gray-500 dark:text-gray-300">
                    Are you sure you want to delete{' '}
                    <span className="text-red-500 text-bold">
                      &quot;{state.deletingApplication?.name}&quot;
                    </span>{' '}
                    application?
                  </p>
                  <div className="flex justify-center items-center space-x-4">
                    <button
                      type="button"
                      className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                      onClick={() => closeConfirmationModal()}
                    >
                      No, cancel
                    </button>
                    <button
                      type="submit"
                      className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
                      onClick={() => deleteApplication(state.deletingApplication?.id!)}
                      disabled={disabled}
                    >
                      Yes, I&apos;m sure
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default Applications;
