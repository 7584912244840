import { useMutation, useQueryClient } from '@tanstack/react-query';
import { labsService } from '../../services/LabsService';
import { WebhookModel } from '../../models/webhook.model';

export const useTestWebhookUrl = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, any, WebhookModel, unknown>({
    mutationFn: async (model: WebhookModel) => labsService.testWebhook(model),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['webhook'] });
    },
  });
};
